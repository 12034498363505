import React from "react";
import '../css/styles.css';
import RequestService from "../services/RequestService";
import {banterdomeFplApiUrl, leagueId, draftLeagueId} from "../configuration/settings";
import dateservice from "../services/dateservice";
import Refresh from "./Refresh";

class Transfers extends React.Component {
    _isMounted = true;
    constructor(props) {
        super(props);
        this.state = {
            transfers: null,
            error: false,
            time: null
        }
    }

    refresh = () =>{
        let draft = (localStorage.getItem('draft') === 'true')
        let infoLeagueId = draft ? draftLeagueId : leagueId
        this.getInfo(false, infoLeagueId, draft);
    }

    getInfo(useCache, infoLeagueId, draft){
      RequestService.request('GET', `${banterdomeFplApiUrl}/transfer/gettransfers/${infoLeagueId}`, [{key: "useCache", value: useCache},{key: "draft", value: draft}])
          .then(res =>{
            console.log(res);
            if(res.error !== null && res.error === "Failed to get transfers"){
              this.setState({error:true});
            }

            if(res.error == null){
              this.setState({transfers:res.info});
              this.setState({time: res.time})
              this.setState({error:false});
            }
          })
      .catch(err =>{
          console.log(err);
          this.setState({error: true});
      });
    }

    componentDidMount(){
        this._isMounted = true;
        let draft = (localStorage.getItem('draft') === 'true')
        let infoLeagueId = draft ? draftLeagueId : leagueId

        if(this._isMounted){
          this.getInfo(true, infoLeagueId, draft);
        }    
    }

    render(){
        var content;
        var time;
        var error = "";

        if(this.state.error){
          error = <div>There has been an error fetching transfers, if the game isn't updating, let me know</div>;
        }

        if(this.state.time !== null){
          time = dateservice.formatDate(this.state.time);
        }

        if(this.state.transfers !== null){
            content = 
              this.state.transfers.map(transfer =>{
                var transfers = [];

                transfer.transfers.map(t => {
                  transfers.push(<div><span className="green">&#9650;</span>{t.inName}<span className="red">&#9660;</span>{t.outName} </div>)
                });

                if(transfers.length > 0){
                  return( 
                    <div id="content">
                      <b>{transfer.managerName}</b>
                      {transfers}
                    </div>)
                }
              });

        }
        return(
            <div style={{textAlign: 'center'}} className="container">
                <h1>Transfers</h1>
                <Refresh refresh={this.refresh} time={time} error={error}  />
                {content}
            </div>);
    }

}

export default Transfers