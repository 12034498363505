import React from "react";
import '../css/styles.css';
import SettingsSwitch from "./SettingsSwitch";

class Settings extends React.Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            settings: {"minusPoints": false, "showPoints": false, "showTransfers": false, "draft": false }
        }
    }

    componentDidMount(){
        this._isMounted = true;

        if(this._isMounted){
            var settings = this.state.settings;
            var keys = Object.keys(settings);

            keys.forEach(key =>{
                var value = localStorage.getItem(key);                 
                settings[key] = (value === 'true');            
            });

            this.setState({settings: settings});
        }
    }

    handleCheckboxChange = (event) =>{
        var settings = this.state.settings;

        settings[event.target.id] = event.target.checked;

        this.setState({settings: settings});
        localStorage.setItem(event.target.id, event.target.checked);
    }

    render(){
        return(
            <div style={{textAlign: 'center'}} className="container">
                <h1>Settings</h1>
                <div>
                    <b>League Selection</b>
                    <SettingsSwitch onClick={this.handleCheckboxChange} id={"draft"} 
                            switchValue={this.state.settings["draft"]} text={"View Draft"}  />
                </div>
                <div>
                    <b>Main Page</b> {/* could eventually get these from db?*/}
                    <SettingsSwitch onClick={this.handleCheckboxChange} id={"showPoints"} 
                        switchValue={this.state.settings["showPoints"]} text={"Show player points"}  />
                    <SettingsSwitch onClick={this.handleCheckboxChange} id={"showTransfers"} 
                        switchValue={this.state.settings["showTransfers"]} text={"Show transfers"}  />
                </div>
                <div>
                    <b>Live League</b>
                    <SettingsSwitch onClick={this.handleCheckboxChange} id={"minusPoints"} 
                        switchValue={this.state.settings["minusPoints"]} text={"Show -points taken"}  /> 
                </div>
            </div>
        )
    }
}

export default Settings