import React from "react";

class Player extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
          isTransfer: false,
        };
    };
    
    showTransfer = (e) => {
        this.setState({isTransfer: !this.state.isTransfer});
    }
    render(){
        var information = 
        {
            name: this.props.player.name,
            teamName: this.props.player.teamName,
            points: this.props.player.points,
            captaincy: (this.props.player.isCaptain ? "(C)" : "") + (this.props.player.isViceCaptain ? "(V)" : ""),
            direction: String.fromCharCode(9650)
        };
        
        if(this.state.isTransfer && this.props.player.previousPlayer !== null){
            information.name = this.props.player.previousPlayer.name;
            information.teamName = this.props.player.previousPlayer.teamName;
            information.points = this.props.player.previousPlayer.points;
            information.direction = String.fromCharCode(9660);
            information.captaincy = "";
        }

        return(
            <div className="player" onClick={this.showTransfer}>
                <span className={this.state.isTransfer ? "red" : "green"}>
                    {this.props.player.previousPlayer !== null ? information.direction  : "" }
                </span>
                <span class={"dot " + information.teamName.replaceAll(' ','').toLowerCase()}></span>
                <span className="playerPoints">{information.points}</span>
                <br></br>
                <span>{information.name.substring(0,12) + information.captaincy}</span>
            </div>
        )
    }
}

export default Player;