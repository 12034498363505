import React from "react";

class Refresh extends React.Component{

    render(){
        return(
            <div className="center">
              <span hidden={this.props.time === null || this.props.error !== "" ? "true" : ""}>This data is correct as of {this.props.time} </span>
              {this.props.error}
              <button onClick={this.props.refresh}>Refresh</button>
              <hr></hr>
            </div>
        )
    }
}

export default Refresh;