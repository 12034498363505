import React from "react";
import '../css/styles.css';
import RequestService from "../services/RequestService";
import {banterdomeFplApiUrl} from "../configuration/settings";
import WorldCup from '../images/WORLDCUP.png';
import Euro from '../images/EURO.png';
import Fpl from '../images/FPL.png'

class Titles extends React.Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            persons: null,
            error: false,
        }
    }

    getInfo(useCache){
        RequestService.request('GET', `${banterdomeFplApiUrl}/title/gettitles`, [{key: "useCache", value: useCache}])
          .then(res =>{
            if(res.error !== null && res.error === "Failed to get titles"){
              this.setState({error:true});
            }
            
            if(res.error == null){
              console.log(res);
              this.setState({persons:res});
              this.setState({error:false});
            }  
          })
          .catch(err =>{
            this.setState({error:true})
            console.log(err);
          });
      }
    
      componentDidMount(){
        this._isMounted = true;
    
        if(this._isMounted){
          this.getInfo(true);
        }    
      }

      getImageSource(title){
        switch(title){
            case "WORLD CUP":
                return WorldCup;
            case "EURO":
                return Euro;
            case "FPL":
                return Fpl;
        }
      }

    render(){
        var content = [];

        if(this.state.persons !== null){
            this.state.persons.map(person => {

                var titles = [];

                person.titles.map(title => 
                    {
                        titles.push
                        (
                                <figure style={{display:'inline-block'}}>
                                <img src={this.getImageSource(title.name)}></img>
                                <figcaption className="smallFont">{title.year}</figcaption>
                                </figure>
                        )
                    }
                )

                content.push(
                <div>
                    <div><b>{person.name}</b></div>
                    <div>{titles}</div>
                </div>)
            })
        }

        return(
            <div style={{textAlign: 'center'}} className="container">
                <h1>Titles</h1>
                {content}
            </div>
        )
    }
}

export default Titles