const setLoggedIn = () =>{
    var now = new Date();

    var loggedIn = now.getTime() + 24 * 60 * 60 * 1000
    localStorage.setItem('loggedIn', loggedIn);
}

const isLoggedIn = () =>{
    var now = new Date();
    var loggedIn = localStorage.getItem('loggedIn');

    if(loggedIn === null){
        return false;
    }
    
    if(now.getTime() > loggedIn){
        localStorage.removeItem('loggedIn');
        return false;
    }

    return true;
}

const logOut = () =>{
    localStorage.removeItem('loggedIn');
}

export default{
    setLoggedIn,
    isLoggedIn,
    logOut
}