import React from "react";
import RequestService from "../../services/RequestService";
import { banterdomeFplApiUrl } from '../../configuration/settings';
import { Accordion } from "react-bootstrap";
import Game from "./Game";


class Games extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            games: [],
            errorMessage: "",
        }
    }

    componentDidMount(){
        RequestService.request('GET', `${banterdomeFplApiUrl}/game/games`)
        .then(res => {
            this.setState({errorMessage: ""});
            console.log(res);
            this.setState({games: res});
        })
        .catch(err => {
            console.log(err);
            this.setState({errorMessage: "Error fetching games, if the game isn't updating let me know"});
        });
    }

    render(){
        var content = [];

        this.state.games.map(g => {
            content.push(<Game game={g} />)
        });
        
        return(
                <div style={{textAlign: 'center'}} className="container">
                <h1>Games</h1>
                <br></br>
                <div>{this.state.errorMessage}</div>
                    <div>   
                        <Accordion>
                            {content}
                        </Accordion>
                    </div>         
                </div>
        )
    }
}

export default Games;
