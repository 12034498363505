import React from "react";
import '../css/styles.css'
import {banterdomeFplApiUrl, leagueId} from "../configuration/settings";
import RequestService from "../services/RequestService";
import dateservice from "../services/dateservice";
import Refresh from "./Refresh";

class LiveLeague extends React.Component {
  _isMounted = true;
  constructor(props) {
    super(props);
    this.state = {
      league: null,
      error: false,
      time: null,
      showHit: false
    }
  }

  getInfo(useCache){ //could this be refactored into one component?
    RequestService.request('GET', `${banterdomeFplApiUrl}/league/getliveleague/${leagueId}`, [{key: "useCache", value: useCache}])
      .then(res =>{
        if(res.error !== null && res.error === "Failed to get league"){
          this.setState({error:true});
        }
        
        if(res.error == null){
          this.setState({league:res.info});
          this.setState({time: res.time});
          this.setState({error:false});
        }  
      })
      .catch(err =>{
        this.setState({error:true})
        console.log(err);
      });
  }

  refresh = () => {
    this.getInfo(false);
  }

  componentDidMount(){
    this._isMounted = true;

    if(this._isMounted){
      this.getInfo(true);
      this.setState({showHit : (localStorage.getItem('minusPoints') === 'true')});
    }    
  }

  render()
    {
        var content = [];
        var time;
        var error = "";

        if(this.state.error){
          error = <div>There's been an error fetching the league, if the game isn't updating, let me know</div>;
        }

        if(this.state.time !== null){
          time = dateservice.formatDate(this.state.time);
        }

        if(this.state.league !== null){
            this.state.league.map(standing => { content.push(<div className="leagueContainer">
                                                                <span className="liveLeagueData">{standing.name}</span>
                                                                <span className="liveLeagueTotal">{standing.overallPoints}</span>
                                                                <span className="liveLeaguePoints">{standing.gameweekPoints}</span>
                                                                {this.state.showHit ? <span className="liveLeagueTotal">{standing.hit}</span> : ""}
                                                            </div>)})
        }

        return(   
            <div style={{textAlign: 'center'}} className="container">
                <h1>Live League</h1>
                <Refresh refresh={this.refresh} time={time} error={error}  />
                <div className="leagueContainer">
                    <span className="liveLeagueData">Name</span>
                    <span className="liveLeagueTotal">Total</span>
                    <span className="liveLeaguePoints">Gameweek</span>
                    {this.state.showHit ? <span className="liveLeagueTotal">Hit</span> : ""}
                </div>
                {content}
            </div>
        )
    }
}

export default LiveLeague