import React from "react";
import '../css/styles.css'
import dateservice from "../services/dateservice";
import RequestService from "../services/RequestService";
import {banterdomeFplApiUrl, leagueId, draftLeagueId} from "../configuration/settings";
import AuthenticationService from "../services/AuthenticationService";
import Refresh from "./Refresh";
import Player from "./Player";

class Home extends React.Component {
  _isMounted = true;
  constructor(props) {
    super(props);
    this.state = {
      teams: null,
      error: false,
      searchResult: null,
      searchType: "player",
      searchValue: "",
      searchTerm: "",
      time: null
    };
  };

  clearButtonClick = (e) =>{
    this.setState({searchTerm: ""});
    this.setState({searchResult: null});
  };

  handleSearchTypeChange = (e) =>{
    this.setState({searchType : e.target.value})
    this.setState({searchResult : null})
       
    if(this.state.teams !== null && this.state.searchTerm.length > 0){
      this.search(this.state.searchTerm, e.target.value);
    }
  }

  handleSearchChange = (e) =>{
    this.setState({searchTerm: e.target.value})

    if(e.target.value.length < 1 || this.state.teams === null){
      this.setState({searchResult: null});
      return;
    }
    this.search(e.target.value, this.state.searchType);
  }

  search(searchTerm, searchType){
    searchTerm = searchTerm.toLowerCase();
    var content = [];     

    if(searchType === "player") //enum
    { 
      var found = this.searchPlayers(searchTerm);
      found.forEach((foundResult) => {content.push(<div><b>{foundResult.key + ":"}</b><br></br><span>{foundResult.value}</span><br></br></div>)});
    } 
    else
    {
      var found = this.searchTeams(searchTerm);;
      found.forEach((foundResult) => 
        {
          content.push(
                        <div>
                          <h3>
                          <span className= {"dotSearch " + foundResult.key.replaceAll(' ','').toLowerCase()}></span>
                          <b>{foundResult.key}</b></h3>
                          <div>{this.getInnerContent(foundResult.managers)}</div>
                          
                        </div>)});
    }

    this.setState({searchResult: content});
  }

  getInnerContent(managers){
    var innerContent = [];

    managers.forEach((manager) => 
    {
      innerContent.push(
        <div><b>{manager.key}:</b><br></br>{manager.value}</div>
      )
    });

    return innerContent;
  }

  searchPlayers(searchTerm){
    var found = [];

    this.state.teams.forEach((team) =>{
      team.players.forEach((player) =>{
        if(player.name.toLowerCase().startsWith(searchTerm)){
          var index = found.findIndex(x => x.key === player.name);

          if(index !== -1){
            found[index].value += ", " + team.managerName + this.getPlayerInformation(player);
          }
          else
          {
            found.push({key: player.name, value: team.managerName + this.getPlayerInformation(player)})
          }
        }
      });
    });

    return found;
  } 
  
  searchTeams(searchTerm){
    var found = [];

    this.state.teams.forEach((team) =>{
      team.players.forEach((player) =>{
        if(player.teamName.toLowerCase().startsWith(searchTerm)){
          
          var index = found.findIndex(x => x.key === player.teamName);

          //team already exists in results
          if(index !== -1){

            var managerIndex = found[index].managers.findIndex(x => x.key === team.managerName)

            //manager already exists in results
            if(managerIndex !== -1){
              found[index].managers[managerIndex].value += ", " + this.getPlayerString(player);
            }
            else{
              found[index].managers.push({key: team.managerName, value: this.getPlayerString(player)})
            }                
          }
          else{
            //team doesnt exist yet in results
            found.push({key: player.teamName, managers: [{key: team.managerName, value: this.getPlayerString(player)}]});
          }
        }
      })
    })
    return found;
  }

  getPlayerString(player){
    return player.name + this.getPlayerInformation(player);
  }

  getPlayerInformation(player){
    return (player.isCaptain ? "(C)" : "") + (player.isViceCaptain ? "(VC)" : "") + (player.squadNumber > 11 ? "(bench)" : "");
  }

  getInfo(useCache, infoLeagueId, showPoints, showTransfers, draft){
    RequestService.request('GET', `${banterdomeFplApiUrl}/team/getteams/${infoLeagueId}`, 
        [{key: "useCache", value: useCache}, {key: "withPoints", value: showPoints}, {key: "withTransfers", value: showTransfers},{key: "draft", value: draft}])
      .then(res =>{
        if(res.error !== null && res.error === "Failed to get teams"){
          this.setState({error:true});
        }

        if(res.error == null){
          this.setState({teams:res.info});
          this.setState({time: res.time});
          this.setState({error:false});
        }  
      })
      .catch(err =>{
        this.setState({error:true})
        console.log(err);
      });
  }

  refresh = () =>{
    let draft = (localStorage.getItem('draft') === 'true')
    let infoLeagueId = draft ? draftLeagueId : leagueId

    this.getInfo(
        false,
        infoLeagueId,
        (localStorage.getItem('showPoints') === 'true'),
        (localStorage.getItem('showTransfers') === 'true'),
        draft);
  }

  componentDidMount(){ 
    this._isMounted = true;

    if(this._isMounted){
        let draft = (localStorage.getItem('draft') === 'true')
        let infoLeagueId = draft ? draftLeagueId : leagueId
        this.getInfo(
            true,
            infoLeagueId,
            (localStorage.getItem('showPoints') === 'true'),
            (localStorage.getItem('showTransfers') === 'true'),
            draft);
    }    
  }

  render() {
    var content;
    var time;
    var error = "";

    if(this.state.time !== null){
      time = dateservice.formatDate(this.state.time);
    }

    if(this.state.error){
      error = <div>There has been an error fetching teams, if the game isn't updating, let me know</div>;
    }
   
    if(this.state.teams !== null){

      var showPoints = (localStorage.getItem('showPoints') === 'true')
      //set content
      content =
        this.state.teams.map(team => 
          {
          var gk = <Player player={team.players[0]} id={`${team.managerName}${team.players[0].position}0`} showPoints={showPoints}/>

          var def = [];
          var mid = [];
          var atk = [];
          var bench = "";
          
            team.players.forEach(player => {             //refactor this and clean it up, keys for arrays?
              if (player.squadNumber < 12 && player.position === 'Defender')
              {
                def.push(<Player player={player} showPoints={showPoints}/>);
              }
              if (player.squadNumber < 12 && player.position === 'Midfielder'){
                mid.push(<Player player={player} showPoints={showPoints}/>);
              }
              if (player.squadNumber < 12 && player.position === 'Attacker'){
                atk.push(<Player player={player} showPoints={showPoints}/>);
              }
              if (player.squadNumber > 11){
                bench += ` ${player.name} ${showPoints ? player.points : ""},`
              }
            });
          

          return  <div id="content">
                    <div id={"name"+team.managerName}><h3>{team.managerName}</h3></div>
                    <div className ="parent smallFont">{gk}</div>
                    <div className="parent smallFont">{def}</div>
                    <div className="parent smallFont">{mid}</div>
                    <div className="parent smallFont">{atk}</div>
                    <div key="bench" className="smallFont">Bench:</div>
                    <div key={"bench"+team.managerName} className="smallFont">{bench}</div>
                    <br></br>
                  </div>
          }
        )
      }

    return (
      <div style={{textAlign: 'center'}} className="container">
          <Refresh refresh={this.refresh} time={time} error={error}  />
          <div id="search" className="search center" hidden={this.state.teams === null ? "true" : ""}>
            Search: <input type="text" id="txtSearch" value={this.state.searchTerm} onChange={(e) => {this.handleSearchChange(e)}}></input>
            <select id="selSearch" className="searchDropdown" onChange={(e) => {this.handleSearchTypeChange(e)}}>
              <option value="player">Player</option>
              <option value="team">Team</option>
            </select>
            <br></br>
            <button onClick={(e) => this.clearButtonClick(e)}>Clear</button>
            {this.state.searchResult}
            <hr></hr>
          </div>
          {content}
          {!AuthenticationService.isLoggedIn() ? <h2><a href="/signup">Sign up</a></h2> : ""}
      </div>
    )
  }
}

export default Home