import { initializeApp } from 'firebase/app';

const firebaseConfig = {
    apiKey: "AIzaSyDF_Jl6uP2i6lMLGlgNZZ-z4FGD4wR5es4",  /* move these to settings */
    authDomain: "banterdome-2d993.firebaseapp.com",
    projectId: "banterdome-2d993",
    storageBucket: "banterdome-2d993.appspot.com",
    messagingSenderId: "640896882869",
    appId: "1:640896882869:web:07627907afe077f930c6fc",
    measurementId: "G-SS3029BBF3"
  };

const app = initializeApp(firebaseConfig);

