const request = (method, url, queryParams = []) =>{

    var queryParamString = "";
    for (var i = 0; i < queryParams.length; i++)
    {   
        if(i === 0){
            queryParamString += `?${queryParams[i].key}=${queryParams[i].value}`
        } else{
            queryParamString += `&${queryParams[i].key}=${queryParams[i].value}`
        } 
    }

    return fetch(`${url}${queryParamString}`,{
        method: method,
    })
    .then(res => {
        if(res.status === 200){
            return res.json();
        }
        else{
            throw Error(`Error with request ${url}`);
        }
    });
}

//will refactor this and genericise the one above at some point, but need to do error handling for 429 (rate limit)
const authenticatedRequest = (method, url, body, contentType) =>{
    return fetch(url,{
        headers: {
            'Content-Type': contentType,
        }, 
        method: method,
        credentials: 'include',
        body: body
    })
    .then(res => { 
        if(res.status === 200){
            if(res.headers.get('content-type') !== null){ //this is not robust, what if it has a non-json content type? You know, it's never come up.
                return res.json()
            }
        }    
        return res.status;
    })
}

export default {
    request,
    authenticatedRequest
}