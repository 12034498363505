import React from "react"
import Navbar from "react-bootstrap/Navbar"
import Nav from "react-bootstrap/Nav"
import {Link} from 'react-router-dom';
import AuthenticationService from "../services/AuthenticationService";
import '../css/styles.css';

function logout(){
  AuthenticationService.logOut()
  window.location.reload();
};

function BanterdomeNavbar() { 
  
      // the matched child route components become props in the parent
      var authenticated = AuthenticationService.isLoggedIn();
      return (

        <Navbar className="navbar" expand="xl">
        <Navbar.Brand as={Link} to="/">&nbsp;The Banterdome</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar" />
        <Navbar.Collapse id="basic-navbar">
          <Nav className="ml-auto">
            <Nav.Link as={Link} to="/">Home</Nav.Link>
            <Nav.Link as={Link} to="/games">Games</Nav.Link>
            <Nav.Link as={Link} to="/transfers">Transfers</Nav.Link>
            <Nav.Link as={Link} to="/liveleague">Live League</Nav.Link>
            <Nav.Link as={Link} to="/titles">Titles</Nav.Link>
            <Nav.Link as={Link} to="/settings">Settings</Nav.Link>
            { authenticated ? <Nav.Link as={Link} to="/notifications" >Notifications</Nav.Link> : ""}
            { !authenticated ? <Nav.Link as={Link} to="/login" >Log in</Nav.Link> : ""}
            { authenticated ? <Nav.Link as={Link} onClick={logout} to="/" >Log out</Nav.Link> : ""}
          </Nav>
        </Navbar.Collapse>
      </Navbar>
      )
  }
export default BanterdomeNavbar