const formatDate = (dateInput) => {
  var date = new Date(dateInput);
  var hours = date.getHours();
  var minutes = date.getMinutes();
  minutes = minutes < 10 ? '0'+minutes : minutes;
  var strTime = hours + ':' + minutes
  return strTime + " " + date.getDate() + "/" + (date.getMonth()+1) + "/" + date.getFullYear()
}

export default{
    formatDate
}