import React from "react";
import Authentication from "./Authentication";
import AuthenticationService from "../services/AuthenticationService";
import { Redirect } from "react-router-dom";


class LogIn extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            redirectIfLoggedIn: false
        }
    }

    componentDidMount(){
        if(AuthenticationService.isLoggedIn()){
            this.setState({redirectIfLoggedIn: true});
        }
    }

    render(){
        if (this.state.redirectIfLoggedIn) return <Redirect to ="/"/>

        return(
                <div style={{textAlign: 'center'}} className="container">
                <h1>Log in</h1>
                <Authentication history ={this.props.history} endpoint = "/authentication/login" button='Log In' />
                </div>
        )
    }
}

export default LogIn;
