import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import Home from "./components/Home"
import LiveLeague from "./components/LiveLeague";
import BanterdomeNavbar from "./components/Navbar";
import Transfers from "./components/Transfers"        
import Titles from "./components/Titles";
import Settings from "./components/Settings";
import SignUp from "./components/SignUp";
import LogIn from "./components/LogIn";
import Notifications from "./components/Notifications";
import Games from "./components/games/Games";

function App() {
return (
      <main>
          <meta name="viewport" content="width=device-width, initial scale=1"></meta>
          <Router>
            <BanterdomeNavbar />        
            <Switch>
              <Route path="/" component={Home} exact />
              <Route path="/games" component={Games} exact />
              <Route path="/transfers" component={Transfers} exact />
              <Route path="/liveleague" component={LiveLeague} exact/>
              <Route path="/titles" component={Titles} exact />
              <Route path="/settings" component={Settings} exact />
              <Route path="/signup" component={SignUp} exact />
              <Route path="/login" component={LogIn} exact />
              <Route path ="/notifications" component={Notifications} exact />
              <Route component={Error} />
            </Switch>
          </Router>
      </main>
  )
}

export default App;