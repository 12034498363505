import React from "react";
import { Accordion, AccordionButton } from "react-bootstrap";
import '../../css/styles.css';
import dateservice from "../../services/dateservice";

class Game extends React.Component{

    stats(home, away, name){

        if(home.length == 0 && away.length == 0){
           return (<div></div>);
        }
        var homeStats = [];
        var awayStats = [];

        home.map(stat => {
            homeStats.push(<div>{stat.name} ({stat.amount})</div>)
        })
        away.map(stat => {
            awayStats.push(<div>{stat.name} ({stat.amount})</div>)
        })
    return(
        
       <div>
           {name}
            <div className="parent smallFont parentStats">
                <div>
                    {homeStats}
                </div>
                <div>
                    {awayStats}
                </div>
            </div>
       </div>)
    }

    render(){
        var cssClass = "";
        if(this.props.game.started && !this.props.game.finished){
            cssClass = "gameStarted"
        }
        if(this.props.game.finished){
            cssClass = "gameFinished"
        }
        var d = new Date();
        return(
            <Accordion.Item eventKey={`${this.props.game.homeTeam}${this.props.game.awayTeam}`}>
                <Accordion.Button className={cssClass}>
                    {`${this.props.game.homeTeam} ${this.props.game.homeScore ?? ""} ${this.props.game.started ? "-" : "v"} ${this.props.game.awayScore ?? ""} ${this.props.game.awayTeam}`} {!this.props.game.started ? ` - ${dateservice.formatDate(this.props.game.kickOff)}` : ""}
                </Accordion.Button>
                {this.props.game.homeScore === null 
                    ? "" 
                    : 
                        (<Accordion.Body>
                            {this.stats(this.props.game.homeGoals, this.props.game.awayGoals, "Goals")}
                            {this.stats(this.props.game.homeAssists, this.props.game.awayAssists, "Assists")}
                            {this.stats(this.props.game.homePensSaved, this.props.game.awayPensSaved, "Penalties Saved")}
                            {this.stats(this.props.game.homePensMissed, this.props.game.awayPensMissed, "Penalties Missed")}
                            {this.stats(this.props.game.homeOwnGoals, this.props.game.awayOwnGoals, "Own Goals")}
                            {this.stats(this.props.game.homeYellows, this.props.game.awayYellows, "Yellow Cards")}
                            {this.stats(this.props.game.homeReds, this.props.game.awayReds, "Red Cards")}
                            {this.stats(this.props.game.homeSaves, this.props.game.awaySaves, "Saves")}
                            {this.stats(this.props.game.homeBps, this.props.game.awayBps, "Bonus Points System")}
                        </Accordion.Body>)
                }
            </Accordion.Item>
        )
    }
}

export default Game;