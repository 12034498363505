import React from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import RequestService from "../services/RequestService";
import { authenticationUrl } from "../configuration/settings";
import AuthenticationService from "../services/AuthenticationService";

class Authentication extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            username: "",
            password: "",
            canSubmit: false,
            errorMessage: "",
            usernameInvalid: false,
            passwordInvalid: false,  
        }
    }

    onChange = (e) =>{
        this.setState({[e.target.id]: e.target.value})

        if(e.target.id === 'username'){
            this.setState({usernameInvalid: false});
        }

        //change both, password should only be invalid on login failure (unless we apply strong password rules!)
        if(this.state.passwordInvalid){
            this.setState({usernameInvalid: false})
            this.setState({passwordInvalid: false})
        }

        this.setState({canSubmit: false})
        if(e.target.value.length > 0 && (e.target.id === 'password' ? this.state.username.length > 0 : this.state.password.length > 0)){
            this.setState({canSubmit: true});
        }
    }

    signUp = (e) =>{
        e.preventDefault();
        this.setState({errorMessage: ""});
        RequestService.authenticatedRequest('POST', `${authenticationUrl}${this.props.endpoint}`, 
            JSON.stringify({"username": this.state.username, "password":this.state.password}), 'application/json')
        .then(res => {
            if(res === 200){
                AuthenticationService.setLoggedIn();
                this.props.history.push("/")
                window.location.reload()
            }
            else if(res === 429){
                this.setState({errorMessage: "You have been rate limited, try again later or contact an admin"});
            }
            else if(res === 401){
                this.setState({errorMessage: "Username or password incorrect"});
                this.setState({usernameInvalid: true})
                this.setState({passwordInvalid: true})
            }
            else if(res.error === "Username already exists"){
                this.setState({errorMessage: "This username already exists, please choose another"});
                this.setState({usernameInvalid: true})
            }
            else{
                throw Error(`RES: ${res}`);
            }
        })
        .catch(err => {
            console.log(err)
            this.setState({errorMessage: "There has been an error with your request, please contact an admin"});
        })
    }

    render(){
        return(
                <div>
                    <hr></hr>
                    <div>
                        {this.state.errorMessage}   
                    </div>
                    <br></br>
                    <div className="center signUp">
                    <Form id="signup-form">
                        <Form.Group controlId="formUsername">
                        <Form.Control className="text-center" id="username" type="text" isInvalid={this.state.usernameInvalid} placeholder="Username" onChange={this.onChange}/> 
                        </Form.Group>
                        <br></br>
                        <Form.Group controlId="formPassword">
                            <Form.Control className="text-center" id="password" type="password" isInvalid={this.state.passwordInvalid} placeholder="Password" onChange={this.onChange}/>
                        </Form.Group>
                        <br></br>
                        <Button variant="primary" onClick={this.signUp} disabled={!this.state.canSubmit || this.state.usernameInvalid || this.state.passwordInvalid} type="submit">{this.props.button}</Button>
                    </Form>
                    </div>
                </div>
        )
    }
}

export default Authentication;