import React from "react";
import AuthenticationService from "../services/AuthenticationService";
import { Redirect } from "react-router-dom";
import  "../firebase/firebaseInit";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import RequestService from "../services/RequestService";
import { banterdomeFplApiUrl } from '../configuration/settings';
import SubscriptionRow from "./notifications/SubscriptionRow";
import '../css/notifications.css'

class Notifications extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            redirectIfNotLoggedIn: false,
            errorMessage: "",
            subscriptions: [],
            searchPlayers: [],
            searchValue: "",
        }
    }

    componentDidMount(){
        if(!AuthenticationService.isLoggedIn()){
            this.setState({redirectIfNotLoggedIn: true});
        } else {
            const messaging = getMessaging();
            getToken(messaging, { vapidKey: 'BF6L-BJwXiZvUtcfAVcHzlAeuo9GupnaNvOD94YGdygd700Nif9sqn-5e50b9aByhqT3CasruvwFW8dRC1LgMIU' }).then((currentToken) => {
              if (currentToken) {
                RequestService.authenticatedRequest('POST', `${banterdomeFplApiUrl}/notifications/savetoken`, `"${currentToken}"`, 'application/json')
                .then(res => {
                  if(res === 401){
                    this.setState({errorMessage: "There's been an authentication error syncing your notifications with the server."})
                  }
                })
                .catch(err => {
                  console.log(err);
                  this.setState({errorMessage: "There's been an error syncing your notifications with the server."})
                })  
              } else {
                console.log('No registration token available. Request permission to generate one.');
              }
            }).catch((err) => {
              console.log('An error occurred while retrieving token. ', err);
            });
            this.getSubscriptions();
        }   
      }


    getSubscriptions = () =>
    {  //can probably genericise this with the error catching / responses at some point!
      RequestService.authenticatedRequest('GET', `${banterdomeFplApiUrl}/notifications/subscriptions`, null, 'application/json' )
      .then(
        res => {
          if(res === 401 || res === 429 || res === 500){
            this.setState({errorMessage: "There's been an error error fetching your notifications from the server."})
          } else{
            this.setState({subscriptions: res});
          }
        }
      )
      .catch(err => {this.setState({errorMessage: "There's been an error error fetching your notifications from the server."})})
    }

    setParentState = (k, value) =>{ this.setState({[k]: value}); }   

    playerSearch = (searchValue) => 
    {
      this.setState({searchValue: searchValue});

      if(searchValue.length < 1){
        this.setState({searchPlayers: []});
        return;
      }

      var players = [];
      this.state.subscriptions.forEach((p) =>{
        if(p.playerName.toLowerCase().startsWith(searchValue.toLowerCase()) && p.subscriptionTypes.length == 0){
          players.push(p);
        }
      });
        
      this.setState({searchPlayers: players});  
    }

    createSubscriptionRow= (sub) =>{
      return <SubscriptionRow player={sub} subscriptions={this.state.subscriptions}  searchValue={this.state.searchValue}
                              searchPlayers = {this.state.searchPlayers} playerSearch={this.playerSearch} setParentState={this.setParentState} />
    }

    render(){
        if (this.state.redirectIfNotLoggedIn) return <Redirect to ="/login"/>

        var mySubs = [];
        this.state.subscriptions.map(sub =>{ if(sub.subscriptionTypes.length > 0 ){ mySubs.push(this.createSubscriptionRow(sub))}});
        
        var searchResults = [];
        this.state.searchPlayers.map(sub =>{ searchResults.push(this.createSubscriptionRow(sub)); });
        
        return(
                <div style={{textAlign: 'center'}} className="container">
                <h1>Notifications</h1>
                {this.state.errorMessage}
                  <div>
                    <b>My Subscriptions</b>
                    <div className="subscriptionRow">
                      <span className="playerName"> <b>Name</b> </span>
                      <span className="teamName"><b>Team</b></span>
                      <span><b>Goals</b></span>
                      <span><b>Assists</b></span>
                    </div>
                    {mySubs}
                    <hr></hr>
                  </div>
                  <div>
                    <b>Subscribe</b><br></br>
                    <input type="text" placeholder="Player name" onChange={(e) => { this.playerSearch(e.target.value) }} />
                    {searchResults}
                  </div>
                </div>
        )
    }
}

export default Notifications;
