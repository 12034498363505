import React from "react";
import '../../css/notifications.css'
import RequestService from "../../services/RequestService";
import { banterdomeFplApiUrl } from '../../configuration/settings';

class SubscriptionRow extends React.Component{

    checkBoxOnClick = (event) =>
    { 
        var subs = this.props.subscriptions;
  
        if(event.target.checked){
          RequestService.authenticatedRequest('PUT', `${banterdomeFplApiUrl}/notifications/player/${event.target.id}/subscriptionType/${(event.target.className === 'Goal' ? 1 : 2)}/subscribe/true`)
          .then(res => 
            {
                //add subscription type to array
                subs[subs.findIndex(x => x.playerId == event.target.id)].subscriptionTypes.push(event.target.className);
    
                this.deleteFromSearch(event.target.id);
            });
        } 
        else{
          //delete subscription type from array
          RequestService.authenticatedRequest('PUT', `${banterdomeFplApiUrl}/notifications/player/${event.target.id}/subscriptionType/${(event.target.className === 'Goal' ? 1 : 2)}/subscribe/false`)
          .then(res => {
            var index = subs.findIndex(x => x.playerId == event.target.id);
            subs[index].subscriptionTypes.splice(subs[index].subscriptionTypes.indexOf(event.target.className), 1);
            
            //repopulate search incase deleted players should be in there
            this.props.playerSearch(this.props.searchValue);
           })
          .catch(err => { this.props.setParentState("errorMessage", "Error saving your subscription change")});
        }
  
        this.props.setParentState("subscriptions", subs);
    }

    deleteFromSearch = (playerId) =>
    {
        var search = this.props.searchPlayers;
        var index = search.findIndex(x => x.playerId == playerId);

        if(index > -1){
            search.splice(index, 1);  
        }
        this.props.setParentState("searchPlayers", search);
    }

    render(){
        return(
            <div className="subscriptionRow">
                <span className="playerName">{this.props.player.playerName.substring(0,11)}</span>
                <span className="teamName">{this.props.player.team}</span>
                <span className="checkBox"><input type="checkbox" id={this.props.player.playerId} className="Goal" checked={this.props.player.subscriptionTypes.indexOf("Goal") > -1 ? true : ""} onClick={this.checkBoxOnClick} /></span>
                <span className="checkBox"><input type="checkbox" id={this.props.player.playerId} className="Assist" checked={this.props.player.subscriptionTypes.indexOf("Assist") > -1 ? true : ""} onClick={this.checkBoxOnClick}/></span>
            </div>
        )
    }
}

export default SubscriptionRow;