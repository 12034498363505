import React from "react";

class SettingsSwitch extends React.Component{

    render(){
        return(
                <div class="form-switch">
                    <input class="form-check-input" checked={this.props.switchValue} type="checkbox" id={this.props.id} onClick={this.props.onClick} />
                    <label class="form-check-label" style={{display: 'inline-block', width: '150px', textAlign: 'left' }} for="flexSwitchCheckDefault"> 
                        &nbsp;{this.props.text}
                    </label>
                </div>
        )
    }
}

export default SettingsSwitch;